.otp-container-login-page{
    box-shadow: 0px 3px 6px #00000029;
    margin: 0px 25px;
    border-radius: 6px;
    position: relative;
    top: -40px;
    z-index: 999;
    background: white;
    min-height: 45vh;
    padding: 50px 30px;
}

.otp-source-container-cma{
    text-align: left;
    display: flex;
    flex-direction: column;
    grid-gap: 15px;
}

.otp-source-dropdown-cma{
    height: 35px;
}

.otp-source-dropdown-cma .ant-select-selector{
    border: none !important;
    background: #F4F4F4 !important;
    text-align: left !important;
    font-size: 16px !important;
    height: 40px !important;
    display: flex !important;
    align-items: center !important;
    border-radius: 4px !important;
}

#otp-source-generate-btn{
    color: white !important;
    background: var(--primary-color);
    border-radius: 4px;
    font-weight: 100;
    width: 80px;
    font-size: 15px;
    padding: 0;
    height: 40px;
    border: none;
}

.additional-trigger-timer-count{
    color: #0075A3;
}

.se-down-arrow-icon svg path{
    fill: #707070;
}