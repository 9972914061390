.login-form-footer{
    height: 11lvh;
    width: -webkit-fill-available;
    position: fixed;
    bottom: 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 2.5lvw 0 2.5lvw;
}

.login-form-footer-content{
    -webkit-font-smoothing: auto;
    color: white;
    font-size: 12px;
    line-height: 18.8px !important;
}

@media screen and  (max-width: 1366px) {
    .login-form-footer-content{
        font-size: 9px;
        line-height: 14.14px !important;
    }
}
