.App {
  margin: 0 4rem;
}
.ant-typography {
  font-size: 18px; /* over-riding antd-typography font-size */
}
.card-wrapper {
  width: 100%;
  box-shadow: 0px 3px 17px #00000029;
  border-radius: 12px;
}
.card-wrapper .ant-card-head {
  /* over-riding antd-card-header styles */
  background: #f44067 0% 0% no-repeat padding-box;
  border-radius: 12px;
  text-align: center;
  color: #ffffff;
  letter-spacing: 0px;
  font-size: 24px;
}
.form-btn {
  width: 120px;
  height: 32px !important;
  font-size: 12px !important;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 22px !important;
  border: none;
}
.form-btn:disabled {
  color: #ffffff;
  opacity: 0.3;
}
/* over-riding antd-spinner styles */
.ResetPassword-wrapper .ant-spin .ant-spin-dot-item {
  background-color: #f44067;
}
.ResetPassword-wrapper .ant-spin .ant-spin-text {
  color: #f44067;
}

.SE-theme.card-wrapper .ant-card-head{
  background: #009530 0% 0% no-repeat padding-box;
}

.SE-theme.ResetPassword-wrapper .ant-spin .ant-spin-dot-item {
  background-color: #009530;
}
.SE-theme.ResetPassword-wrapper .ant-spin .ant-spin-text {
  color: #009530;
}

:root {
  --primary-color: #f44067;
}