.background-screen-reset-password{
    background: #F4F4F4;
    height: 35vh;
    border-radius: 0 0 30px 30px;
    color: var(--primary-color);
    font-size: 20px;
    padding-top: 5vh;
    display: flex;
    justify-content: center;
    box-shadow: 0px 3px 6px #00000029;
}

.background-screen-verification{
    background: #F4F4F4;
    height: 40vh;
    border-radius: 0 0 30px 30px;
    color: var(--primary-color);
    font-size: 22px;
    gap: 35px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 3px 6px #00000029;
}

.background-screen-verification svg path{
    fill: var(--primary-color);
}

.background-screen-verification svg{
    width: 48px;
    height: 52px;
}

.float-label {
    position: relative;
}

.float-label>input,
.float-label>span{
    border-radius: 6px;
    border: 1px solid var(--primary-color) !important;
    /* margin-bottom: 20px; */
    height: 50px;
}
  
.label {
    font-weight: normal;
    /* position: absolute; */
    pointer-events: none;
    /* left: 12px; */
    /* top: 11px; */
    transition: 0.2s ease all;
    color: var(--primary-color);
}
  
.as-label {
    top: -8px;
    font-size: 12px !important;
    background: white;
    padding: 0 4px;
    margin-left: -4px;
    z-index: 99;
    position: absolute;
    left: 12px;
}

.details-input-container{
    padding: 20px;
    box-shadow: 0px 3px 17px #00000029;
    margin: 0 25px;
    position: relative;
    bottom: 28vh;
    background: white;
    border-radius: 6px;
}
 
.form-btn-save-cma{
    background-color: var(--primary-color) !important;
    color: white !important;
    border: none;
    border-radius: 3px !important;
    padding: 0 25px !important;
    font-size: 15px !important;
    margin-top: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.disable-btn{
    opacity: 0.4;
    pointer-events: none;
}

.password-policy-cma>div{
    font-size: 15px;
    margin-left: 5px;
    font-weight: 600;
}

.password-policy-cma>ul{
    padding-left: 0;
    margin-left: 5px;
    line-height: 23px;
    font-weight: 500;
    margin-top: 5px;
    font-size: 13px;
}

.password-policy-cma{
    margin-bottom: 25px;
}

.reset-pass-form .terms-and-conditions-cma {
    font-size: 14px;
    width: 100%;
}

.reset-pass-form .terms-and-conditions-cma span {
    color: #0075A3;
    cursor: pointer;
}

.reset-pass-form-cma .ant-form-item-explain-error{
    font-size: 11px;
}