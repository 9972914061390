.ResetPassword-wrapper.eu-reset-password-wrapper{
    position: absolute;
    width: 100%;
    bottom: 11lvh;
}

.new-card-wrapper {
    width: 100%;
    border-radius: 0;
    border: none;
    background: none;
}

.new-card-wrapper .ant-card-body{
    padding: 0;
}

.new-card-wrapper .ant-row{
    background: white;
}

.transparent-green-cover{
    background: #008A16;
    height: 4lvh;
    opacity: 0.4;
}

.green-cover{
    background: #3DCD58;
    height: 4lvh;
}


.new-reset-pass-form {
    margin-top: 10px;
}

.new-reset-pass-form .ant-form-item-label {
    font-weight: 500;
    display: contents;
    padding: 0;
}

.new-reset-pass-form .ant-form-item{
    margin-bottom: 15px;
}

.new-reset-pass-form.ant-form-large .ant-form-item .ant-form-item-label>label{
    height: 28px;
}

.new-reset-pass-form .ant-input-affix-wrapper,
.new-reset-pass-form #reset-password-form_username{
    font-size: 16px;
    padding-left: 7px !important;
    height: 35px;
    min-height: 35px !important;
    border-radius: 5px !important;
    border: 1px solid #626469 !important;
    background-color: #fff !important;
}

.new-gen-otp-btn,
.new-gen-otp-btn:hover {
    background-color: #009530 !important;
    margin-right: 16px;
    height: 32px !important;
    border-radius: 4px !important;
    color: white;
    width: 93%;
}

.new-gen-otp-btn:disabled{
    background: #009530;
    height: 32px !important;
    border-radius: 4px !important;
    opacity: 0.4;
    color: white;
    width: 93%;
}

.new-reset-pass-form .clear-form-btn,
.new-reset-pass-form .clear-form-btn:hover{
    background: #ffffff 0% 0% no-repeat padding-box;
    border: none;
    outline-offset: -2px;
    color: #009530 !important;
    outline: 2px solid #009530;
}

.new-reset-pass-form .new-terms-and-conditions {
    font-size: 14px;
    width: 100%;
}

.new-reset-pass-form .new-terms-and-conditions span {
    color: #0075A3;
    cursor: pointer;
    position: relative;
    bottom: 5px;
}

.new-reset-pass-form .form-btns-group {
    display: flex;
    justify-content: center;
}

.new-reset-pass-form .form-btns-group .err-msg {
    margin: 0 0 18px 0;
    text-align: center;
}

.new-card-wrapper-heading{
    font-size: 26px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
}

.new-reset-pass-form .ant-form-item .ant-form-item-label >label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before{
    display: none;
}

.new-reset-pass-form.ant-form-vertical .ant-form-item .ant-form-item-control{
    width: 85%;
}

 .reset-pass-form-container{
    padding: 2.5lvh 2lvw 0 3lvh;
}

.reset-pass-card .expired-text {
    font-size: 21px;
    margin-bottom: 15px;
    font-weight: 500;
  }

.reset-pass-card .new-password-policy-box {
    display: flex;
    align-items: center;
}

.reset-pass-card .new-password-policy {
    border: none;
    padding: 0;
    margin-left: 0;
}

.reset-pass-card .new-password-policy legend {
    padding-inline-end: 5px;
    font-size: 18px;
    margin-bottom: 5px;
}

.reset-pass-card .new-password-policy ul {
    padding-inline-start: 3px;
    margin-top: 0;
}

.new-reset-pass-form .form-btns-group .new-err-msg {
    margin: 0 0 10px 0;
    text-align: center;
}

.cs-title {
    font-size: 24px !important;
    font-weight: 500;
}

@media screen and  (max-width: 1366px) {

    .cs-title {
        font-size: 20px !important;
    }

    .reset-pass-card .expired-text{
        font-size: 17px !important;
    }

    .eu-reset-password-wrapper form div{
        font-size: 12px;
    }

    .new-reset-pass-form .new-terms-and-conditions,
    .reset-pass-card .new-password-policy ul,
    .new-reset-pass-form .form-btns-group .new-err-msg {
        font-size: 12px;
    }

    .new-otp-source-container{
        margin-bottom: 10px;
    }

    .new-reset-pass-form .ant-input-affix-wrapper,
    .new-reset-pass-form #reset-password-form_username{
    font-size: 14px !important;
    }

    .login-form-footer-content{
        font-size: 9px;
    }
}
