.otp-section-modal .ant-modal-body {
  display: flex;
  justify-content: center;
}
.verify-otp-card {
  text-align: center;
}
.verify-otp-card .card-sub-header {
  color: #f44067;
}
.verify-otp-card .otp-container {
  justify-content: center;
  margin: 20px 0px 10px 0;
}

.verify-otp-card .new-card-sub-header {
  color: black !important;
  font-size: 16px;
  display: block;
  text-align: left;
}

.verify-otp-card .otp-input {
  margin-right: 16px;
  width: 2.5rem !important;
  height: 2.5rem;
  font-size: 2rem;
  background-color: #f5f5f5;
  border: none;
  color: #818486;
  border-radius: 7px;
}

.verify-otp-card .receive-otp-info {
  color: #707070;
  font-size: 15px;
  margin-bottom: 5px;
}
.verify-otp-card .resend-otp-txt,
.verify-otp-card .retry-count-txt {
  color: #1890fb;
  font-size: 15px;
}
.verify-otp-card .verifyotp-btn-groups {
  margin: 40px 0px 20px 0px;
}
.verify-otp-card .submit-otp-btn,
.verify-otp-card .submit-otp-btn:hover {
  background: #f44067 0% 0% no-repeat padding-box;
  margin-right: 16px;
}
.verify-otp-card .clear-otp-btn,
.verify-otp-card .clear-otp-btn:hover {
  color: #f44067 !important;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: none;
  outline: 2px solid #f44067;
  outline-offset: -2px;
}
.reset-modal-space .ant-space-item {
  align-self: center;
}
.reset-modal-space .reset-modal-title {
  width: 260px;
  text-align: center;
  margin-top: 0;
  line-height: 1;
  font-size: 20px;
  color: #5F5F5F;
}
.reset-modal-space .reset-modal-sub-title {
  display: block;
  text-align: center;
  line-height: 1.1;
  font-size: 14px;
  color: #3F3D56;
}

.verify-email-container{
  display: flex;
  justify-content: center;
  align-items: baseline;
  grid-gap: 25px;
  position: relative;
  right: 40px;
}

.verification-heading{
  font-size: 20px;
}

.SE-theme.verify-otp-card .card-sub-header {
  color: #009530;
}

.SE-theme.verify-otp-card .submit-otp-btn,
.SE-theme.verify-otp-card .submit-otp-btn:hover {
  background: #009530;
}

.SE-theme.verify-otp-card .clear-otp-btn,
.SE-theme.verify-otp-card .clear-otp-btn:hover {
  color: #009530 !important;
  outline: 2px solid #009530;
}

.new-verify-email-container{
  display: flex;
  justify-content: center;
  align-items: baseline;
  grid-gap: 5px;
  flex-direction: column;
}

.ml-0{
  margin-left: 0 !important;
}

.mb-10{
  margin-bottom: 10px !important;
}

.new-verification-heading{
  font-size: 22px;
  line-height: 24px;

}

.verify-otp-card .new-otp-container {
  display: flex;
}

.mt-20{
  margin-top: 20px !important;
}

.new-otp-container .otp-input{
  margin-right: 16px;
  width: 32px !important;
  height: 36px !important;
  font-size: 26px !important;
  border: 1px solid #626469 !important;
  color: black;
  border-radius: 4px !important;
  text-align: center;
  padding: 0 !important;
  /* padding-left: 2px !important; */
}

.verify-otp-card .new-verifyotp-btn-groups {
  margin: 20px 0px;
  display: flex;
}

@media screen and  (max-width: 1366px) {
  .new-verification-heading{
      font-size: 18px;
      line-height: 20px;
  }

  .verify-otp-card .new-card-sub-header{
      font-size: 14px;
  }

  .verify-otp-card .receive-otp-info, .verify-otp-card .resend-otp-txt,
   .verify-otp-card .retry-count-txt {
      font-size: 13px;
  }
 
  .new-otp-container .otp-input{
      margin-right: 12.5px;
      width: 28px !important;
      height: 34px !important;
      font-size: 24px !important;
  }
}
