.login-form{
    height: calc(100vh - 110px);
}

.login-form-container {
    padding: 60px 45px;
    width: 400px;
    border-radius: 5px;
    box-shadow: 0 0 30px 3px rgba(0, 0, 0, 0.15);
    background-color: #ffffff;
    margin: auto;
    height: 300px;
}

.login-instruction {
    height: 23px;
    font-size: 22px; 
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 1.65px;
    color: #444444;
    text-align: center;
    width: 100%;
    margin-bottom: 10px; 
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Nunito', sans-serif;
}

.login-form-container input {
    height: 55px;
    border-radius: 2px;
    border: solid 0.6px rgba(208, 208, 208, 0.5);
    background-color: #e5e9ed !important;
}

.login-form-container .message {
    font-size: 12px;
}

.submit-login {
    margin-top: 50px;
    width: 100%;
    height: 53px;
    border-radius: 2px;
    background-color: #F44067 !important;
    color:white !important;
    font-weight: normal;
}

.submit-login * {
    color: white;
}

.forgot-password-trigger {
    margin: 10px;
    cursor: pointer;
    display: block;
    text-align: justify;
    /* text-decoration: underline; */
}

.otp-page-icon{
    margin-right: 8px;
}

.otp-source-dropdown{
    width: 70% !important;
    height: 45px;
}

.otp-source-dropdown .ant-select-selector{
    border: none !important;
    background: #F4F4F4 !important;
    text-align: left !important;
    font-size: 19px !important;
    height: 45px !important;
    display: flex !important;
    align-items: center !important;
}

.otp-source-generate-btn{
    color: white !important;
    background: #F44067;
    border-radius: 3px;
    font-weight: 100;
    width: 25%;
    font-size: 16px;
    border: none;
}

.otp-source-container{
    display: flex;
    grid-gap: 10px;
    margin-bottom: 25px;
    justify-content: space-between;
}

.otp-container{
    margin-top: 50px;
}

.code-input-area .ui.fluid.input>input{
    background: #F4F4F4 !important;
    border: none;
    padding-left: 10px !important;
    font-size: 16px;
    height: 40px;
    width: calc(100% - 15px);
}

.submit-otp-container{
    display: flex;
    justify-content: center;
}

.submit-otp-container button{
    width: 100px;
    font-size: 18px !important;
    font-weight: 100 !important;
    height: 45px !important;
    line-height: 0px !important;
    border: none;
    margin-top: 15px;
}

#error-msg{
    margin-bottom: 15px;
    font-size: 14px;
    font-weight: 400;
    display: block;
    color:rgb(239, 0, 0) !important
}

.ui.negative.container{
    height: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ui.negative.container .header{
    display: flex;
    justify-content: center;
    font-size: 17px;
    background: #FFF1EF;
    border: 1px solid #FFCCC6;
    width: 90%;
    padding: 10px 0;
    border-radius: 5px;
}

.verify-otp-new-user-error-container{
    display: flex;
    justify-content: center;
}

.verify-otp-new-user-error-container h5{
    margin-top: 10px !important;
}

.resend-otp-new-user-page{
    text-align: left;
    margin-bottom: 20px;
    font-size: 12px;
}

.SE-theme.submit-login{
    background-color: #009530 !important;
}

.SE-theme.otp-source-generate-btn{
    background: #009530 !important;
}

.negative-message{
    display: flex;
    justify-content: center;
    font-size: 17px;
    background: #FFF1EF;
    border: 1px solid #FFCCC6;
    width: 99%;
    padding: 10px 0;
    border-radius: 5px;
    margin-bottom: 15px;
    text-align: center;
}

.success-message{
    background: #EDF7ED;
    border: 1px solid #458845;
}

.disable-submit-login{
    opacity: 0.4;
    pointer-events: none;
}

.transparent-green-cover{
    background: #008A16;
    height: 40px;
    opacity: 0.4;
}

.green-cover{
    background: #3DCD58;
    height: 40px;
}

.new-submit-login {
    margin-bottom: 15px !important;
    width: 100%;
    height: 35px;
    font-size: 18px !important;
    border-radius: 5px !important;
    background-color: #008A16 !important;
    color:white !important;
    border: none;
    cursor: pointer;
}

.new-submit-login * {
    color: white;
}

.login-logo-instruction-container{
    width: auto;
    background: white;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 2.5lvh 2.5lvw 2.5lvw 3lvh;
}

.login-infomation{
    position: absolute;
    bottom: 0;
    width: 100%;
}

.new-login-form-container {
    width: 24vw;
}

.new-login-form-container .field{
    margin: 5px 0 1em !important;
}

.new-login-form-container input,
.new-otp-container input{
    font-size: 18px;
    padding-left: 7px !important;
    height: 30px;
    border-radius: 5px !important;
    border: 1px solid #626469 !important;
    background-color: #fff !important;
    width: calc(100% - 15px);
}

.login-form-footer{
    height: 100px;
    width: 100%;
    position: fixed;
    bottom: 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 30px 0 50px;
}

.login-form-footer-content{
    color: white;
}

.new-otp-source-container{
    display: flex;
    grid-gap:10px;
    margin-bottom: 20px;
}

.new-otp-source-container .dropdown-arrow-icon{
    height: 100% !important;
    width: 24px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: inherit !important;
}


.new-otp-source-dropdown{
    width: 65% !important;
    height: 35px;
    margin-right: 10px;
}

.new-otp-source-dropdown .ant-select-selector{
    border: 1px solid #626469  !important;
    background: #fff !important;
    text-align: left !important;
    font-size: 18px !important;
    height: 35px !important;
    display: flex !important;
    align-items: center !important;
    border-radius: 5px !important;
    padding-left: 8px !important;
    padding-right: 0px !important;
}

#new-otp-source-generate-btn{
    cursor: pointer;
    color: black !important;
    background: white;
    border-radius: 5px;
    font-weight: 400;
    width: 30%;
    font-size: 16px;
    padding: 0;
    border: 1px solid #626469;
    height: 35px;
}

#new-OTP-input{
    padding-left: 9px !important;
}

.new-login-instruction {
    font-family: 'Nunito', sans-serif;
    font-size: 26px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    color: #444444;
    text-align: center;
    width: 100%;
    margin-bottom: 18px;
    display: flex;
    align-items: center;
}

.new-negative-message{
    display: flex;
    justify-content: flex-start;
    font-size: 17px;
    width: 99%;
    margin-bottom: 15px;
    color: red;
}

.new-success-message{
    color: #009530;
}

@media screen and  (max-width: 1366px) {

    .new-login-form-container input,
    .new-otp-container input,
    .new-otp-source-dropdown .ant-select-selector,
    #new-otp-source-generate-btn,
    #new-OTP-input,
    .new-submit-login
    {
        font-size: 14px !important;
    }



    .new-verification-heading{
        font-size: 18px;
        line-height: 20px;
    }

    .verify-otp-card .new-card-sub-header{
        font-size: 14px;
    }

    .verify-otp-card .receive-otp-info, .verify-otp-card .resend-otp-txt,
     .verify-otp-card .retry-count-txt {
        font-size: 13px;
    }

}