.reset-pass-card .expired-text {
  font-size: 21px;
  margin-bottom: 15px;
  font-weight: 500;
}
.reset-pass-card .password-policy {
  border: 1px solid #707070;
  border-radius: 12px;
}
.reset-pass-card .password-policy legend {
  padding-inline-start: 5px;
  padding-inline-end: 5px;
  color: #5f5f5f;
  font-size: 18px;
  font-weight: 600;
}
.reset-pass-card .password-policy ul {
  padding-inline-start: 20px;
}
.reset-pass-card .password-policy ul li {
  color: #5f5f5f;
}
.reset-pass-form {
  margin-top: 10px;
}
.reset-pass-form .ant-form-item-label {
  font-weight: 500;
}
.reset-pass-form .gen-otp-btn,
.reset-pass-form .gen-otp-btn:hover {
  background: #faad14 0% 0% no-repeat padding-box;
  margin-right: 16px;
}
.terms-and-conditions-modal .accept-modal-btn,
.terms-and-conditions-modal .accept-modal-btn:hover {
  background: var(--primary-color) 0% 0% no-repeat padding-box;
  margin-right: 16px;
}
.reset-pass-form .clear-form-btn,
.reset-pass-form .clear-form-btn:hover,
.terms-and-conditions-modal .cancel-modal-btn,
.terms-and-conditions-modal .cancel-modal-btn:hover {
  background: #ffffff 0% 0% no-repeat padding-box;
  color: var(--primary-color) !important;
  border: none;
  outline: 2px solid var(--primary-color);
  outline-offset: -2px;
}
.reset-pass-form .terms-and-conditions {
  font-size: 14px;
  width: 100%;
}
.reset-pass-form .terms-and-conditions span {
  text-decoration: underline;
  color: #2d65fc;
  cursor: pointer;
}
.reset-pass-form .form-btns-group {
  display: flex;
  justify-content: center;
}
.reset-pass-form .form-btns-group .err-msg {
  margin: 0 0 18px 0;
  text-align: center;
}
.reset-pass-card .password-policy-box {
  position: absolute;
  right: 45px;
  width: 100%;
}
.terms-and-conditions-modal .terms-modal-title {
  color: #f44067;
  font-size: 23px;
  font-weight: 500;
  border-bottom: 1px solid #707070;
  margin: 0;
  padding-bottom: 0.5em;
}
.terms-and-conditions-modal .terms-modal-description {
  color: #707070;
  font-size: 18px;
  font-weight: 500;
  margin: 0;
}
.terms-and-conditions-modal .terms-modal-text {
  height: 260px;
  display: block;
  overflow: auto;
  color: #707070;
  line-height: 1.35;
  font-size: 16px;
}
.terms-and-conditions-modal .ant-modal-footer {
  text-align: center;
  padding-top: 20px;
}
#terms-content p {
  margin-top: 0;
}

.SE-theme.card-wrapper .ant-card-head,
.SE-theme.reset-pass-form .gen-otp-btn,
.SE-theme.reset-pass-form .gen-otp-btn:hover,
.SE-theme.terms-and-conditions-modal .accept-modal-btn,
.SE-theme.terms-and-conditions-modal .accept-modal-btn:hover {
  background: #009530;
}

.SE-theme.terms-and-conditions-modal .terms-modal-title{
  color: #009530;
}

.SE-theme.reset-pass-form .clear-form-btn,
.SE-theme.reset-pass-form .clear-form-btn:hover,
.SE-theme.terms-and-conditions-modal .cancel-modal-btn,
.SE-theme.terms-and-conditions-modal .cancel-modal-btn:hover {
  color: #009530 !important;
  outline: 2px solid #009530;
}

.se-theme-checkbox .ant-checkbox-checked .ant-checkbox-inner,
.se-theme-checkbox .ant-checkbox-checked .ant-checkbox-inner:hover{
  background-color: #009530 !important;
  border-color: #009530 !important;
}

.se-theme-checkbox .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-inner, 
.ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner{
  border-color: #009530;
}

.theme-checkbox .ant-checkbox-checked .ant-checkbox-inner,
.theme-checkbox .ant-checkbox-checked .ant-checkbox-inner:hover{
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}

.theme-checkbox .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-inner, 
.ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner{
  border-color: var(--primary-color);
}

.download-tnc{
    color: rgb(31, 115, 235);
    grid-gap: 2px;
    cursor: pointer;
    font-size: 15px !important;
    display: flex;
    align-items: center;
}

.changes-tnc-modal-cma{
  max-width: 100% !important;
  height: 100% !important;
}

.changes-tnc-modal-cma .ant-modal-body{
  height: calc(100% - 32vh);
}

.changes-tnc-modal-cma .ant-modal-content{
  height: 100% !important;
}

.changes-tnc-modal-cma .terms-modal-text{
  height: 55vh;
}

.tnc-cma-footer-btns{
  flex-direction: column;
  gap: 10px;
  margin-top: 10px !important;
}

.tnc-cma-footer-btns div:nth-child(2){
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.tnc-cma-footer-btns div:nth-child(2) button{
  border-radius: 6px !important;
  width: 100%;
  height: 40px !important;
  margin-inline-start: 0 !important;
  font-size: 15px !important;
}

.changes-tnc-modal-cma .terms-modal-title{
  border-bottom: none;
  color: var(--primary-color);
}