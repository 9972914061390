.background-screen{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    background: #F4F4F4;
    height: 35vh;
    width: 100%;
    border-radius: 0 0 30px 30px;
    box-shadow: 0px 3px 6px #00000029;
    color: white;
    font-size: 20px;
    padding-top: 5vh;

}


.otp-section-modal .ant-modal-body {
    display: flex;
    justify-content: center;
  }

.verify-otp-card-cma {
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: 0 auto;
    box-shadow: 0px 3px 17px #00000029;
    border-radius: 12px;
    position: relative;
    bottom: 60px;
}

 .verify-icon{
    height: 62px;
    width: 58px;
    margin-top: 40px;
}

.verify-icon path{
  fill: var(--primary-color);
}

.cancel-icon {
    height: 14px;
    width: 14px;
}


.form-container{
   display: flex;
   flex-direction: column;
   gap: 25px 0;
}

.form-container .form-item{
    display: flex;
    flex-direction: column;
    align-items: start;
}

.form-container .heading{
    font-size: 15px;
    color: #202020;
    display: block;
    font-weight: 600;
  }

.form-container .label{
    font-size: 12px;
    line-height: 10px;
    color: #202020;
    text-align: start;
}

.form-container .sub-label{
    font-size: 11px;
    line-height: 16px;
    color: #202020;
    text-align: start;
}

.form-container .input{
  margin: 6px 0;
  border: none;
  background: #F4F4F4;
  height: 35px;
  border-radius: 3px;
  font-size: 15px;
}

.verify-otp-card-cma .mv-4{
    margin: 4px 0;
}


  .verify-otp-card-cma .card-sub-header {
    color: #DE1500;
  }

  .verify-otp-card-cma .card-error-container{
    display: flex;
    gap:0 5px;
  }

  .verify-otp-card-cma .card-error {
    display: block;
    font-size: 11px;
    line-height: 14px;
    text-align: start;
    color: #DE1500;
    margin:2px 0 12px 0;
  }

  .verify-otp-card-cma .otp-container {
    justify-content: center;
    margin: 20px 0px 10px 0;
  }

  .verify-otp-card-cma .receive-otp-info {
    color: #707070;
    font-size: 15px;
  }
  .verify-otp-card-cma .resend-otp-txt,
  .verify-otp-card-cma .retry-count-txt {
    color: #0075A3;
  }
  .verify-otp-card-cma .verifyotp-btn-groups {
    margin: 40px 0px 20px 0px;
    text-align: center;
  }
  .verify-otp-card-cma .submit-otp-btn,
  .verify-otp-card-cma .submit-otp-btn:hover {
    background: var(--primary-color) 0% 0% no-repeat padding-box;
    color: #ffffff;
    width: fit-content;
    border: none;
    border-radius: 3px !important;
  }

  .reset-modal-space .ant-space-item {
    align-self: center;
  }
  .reset-modal-space .reset-modal-title {
    width: 260px;
    text-align: center;
    margin-top: 0;
    line-height: 1;
    font-size: 20px;
    color: #5F5F5F;
  }
  .reset-modal-space .reset-modal-sub-title {
    display: block;
    text-align: center;
    line-height: 1.1;
    font-size: 14px;
    color: #3F3D56;
  }
  
  
  .verification-heading-cma{
    font-size: 22px !important;
    text-transform: capitalize;
    color: var(--primary-color);
    display: block;
    margin-top: 20px;
  }


  .SE-theme.verify-otp-card-cma .card-sub-header {
    color: #009530;
  }
  
  .SE-theme.verify-otp-card-cma .submit-otp-btn,
  .SE-theme.verify-otp-card-cma .submit-otp-btn:hover {
    background: #009530;
    border-radius: 3px !important;
  }
  
  .new-verification-heading{
    font-size: 23px;
  }
  
  .verify-otp-card-cma .new-otp-container {
    justify-content: center;
    margin: 0 0 10px 0;
  }
  
  .mt-20{
    margin-top: 20px !important;
  }
  
  
  .verify-otp-card-cma .new-verifyotp-btn-groups {
    margin: 20px 0px;
  }